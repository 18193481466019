import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumb from "../components/breadcrumb";
import { saveCookie, getCookie } from "../cookies";
import ModalPrecio from "../components/modalPrecio";
const dictionary = require("../dic/dic.json");
class PageTemplate extends Component {
  constructor (props) {
    super(props);
    // console.log(props);
    this.state = { modal: false, priceCookie: false, currentUrl: null };
    this.hideModalPrecio = this.hideModalPrecio.bind(this);
    this.show = this.show.bind(this);
    this.createSchema = this.createSchema.bind(this);
    this.dataLayerEvents = this.dataLayerEvents.bind(this);
  }

  addScript (url) {
    const script = document.createElement("script");
    script.src = url;
    script.async = false;
    document.body.appendChild(script);
  }

  show (name, url = null) {
    if (this.state.priceCookie) {
      this.setState({ [name]: true, priceCookie: false, currentUrl: url });
      return;
    }
    if (url !== null) {
      location.assign(url);
    }
  }

  hideModalPrecio () {
    this.setState({ modal: false });
  }

  createSchema (seo, page, lang) {
    const appJsonAux = [];
    if (page.aplication_json_ld_reviews_gql.aplicationJsonLdReviews != null) {
      appJsonAux.push(page.aplication_json_ld_reviews_gql.aplicationJsonLdReviews);
    }
    // if(appJsonAux==undefined || appJsonAux==null){
    if (typeof window !== "undefined") {
      const elementsModul = document.querySelectorAll(".slide-entry-wrap .slide-entry");
      const itemListElement = [];
      // console.log("elementsModul", elementsModul)
      let position = 1;
      for (const elementModul of elementsModul) {
        const listItem = {
          "@type": "ImageObject",
          contentUrl: elementModul.querySelector("picture img").src,
          name: elementModul.getElementsByClassName("caso-titulo")[0].innerHTML,
          description: elementModul.getElementsByClassName("caso-subtitulo")[0].innerHTML,
          position
        };
        const link = elementModul.getElementsByClassName("boton-secundario");

        if (link.length > 0) {
          listItem.url = link[0].href;
        }
        itemListElement.push(listItem);
        position++;
      }

      if (itemListElement.length > 0) {
        // console.log("app_json", this.state.app_json);
        const itemListElementString = JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ItemList",
          itemListElement
        });
        appJsonAux.push(itemListElementString);
      }
    }

    const pageSchema = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: seo.title,
      description: seo.metaDesc,
      datePublished: page.date,
      dateModified: page.modified
    };
    if (page.author !== null && page.author.node.avatar !== undefined) {
      let authorSchema = {
        "@type": "Person",
        name: page.author.node.name,
        image: page.author.node.avatar.url,
        url: "/" + lang + "/" + page.author.node.slug
      };
      if (page.author.node.name === "billage") {
        authorSchema = {
          "@type": "Organization",
          name: page.author.node.name,
          logo: "/images/logo-billage-color.svg"
        };
      }
      pageSchema.author = authorSchema;
    }
    const pageSchemaString = JSON.stringify(pageSchema);

    appJsonAux.push(pageSchemaString);
    // console.log("JSON", appJsonAux);
    return appJsonAux;
  }

  componentDidMount () {
    // this.addScript("https://code.jquery.com/jquery-3.6.0.min.js");
    this.addScript("/customjs/toggles.js");
    this.addScript("/customjs/jquery-ui.min.js");
    this.addScript("/customjs/owl.carousel.min.js");
    this.addScript("/customjs/custom.js");

    // }

    // const langPage = this.props.pageContext.idioma
    // var casosExito = document.querySelectorAll(
    //   ".slide-entry-wrap .boton-secundario"
    // )

    // for (var i = 0; i < casosExito.length; i++) {
    //   const casoExito = casosExito[i]
    //   casoExito.innerHTML = dictionary[langPage].caso_exito
    // }

    const pageId = this.props.data.wpPage.databaseId;
    // console.log(pageId);

    // calculadora de IVA e IRPF
    if (pageId === 28378) {
      this.addScript("/customjs/calculadoras/iva-irpf.js");
    }

    // calculadora cuota autonomos
    if (pageId === 28695) {
      this.addScript("/customjs/calculadoras/cuota-autonomo.js");
    }

    // calculadora IBAN
    if (pageId === 28749) {
      this.addScript("/customjs/calculadoras/iban.js");
    }

    // calculadora sueldo neto autonomo
    if (pageId === 29250) {
      this.addScript("/customjs/calculadoras/sueldo-neto-autonomo.js");
    }

    // kit digital
    if (pageId === 32068) {
      this.addScript("/customjs/bootstrap.min.js"); // para acordeon FAQ
    }

    // precios
    if (pageId === 4205 || pageId === 36587) {
      // si tiene el parámetro idReseller viene con invitación y se modifica URL de botones de registro
      // URL ejemplo: https://www.getbillage.com?utm_source=billage&utm_medium=email&utm_campaign=be-a-reseller&idReseller=
      if (pageId === 36587) {
        const cookie = getCookie("precios");
        const _self = this;
        if (!cookie) {
          this.setState({ priceCookie: true });
          window.addEventListener("load", () => {
            const links = document.getElementsByTagName("a");
            for (const link of links) {
              const urlAux = link.getAttribute("href");
              link.dataset.url = urlAux;
              if (!urlAux.includes("app.getbillage.com")) {
                link.setAttribute("href", "javascript:void(0)");
                link.removeAttribute("target");
                // link.addEventListener("click", this.show());
                link.addEventListener("click", function (ev) {
                  ev.preventDefault();
                  _self.show("modal", urlAux);
                });
              }
            }
            const spans = document.querySelectorAll("span[onclick]");
            for (const span of spans) {
              let urlAux = span.getAttribute("onclick");
              if (span.dataset.url !== undefined) {
                urlAux = span.dataset.url;
              }
              urlAux = urlAux.replace("location.href=", "");
              urlAux = urlAux.replaceAll("'", "");
              urlAux = urlAux.replace(";", "");
              span.dataset.url = urlAux;
              // span.replaceWith(span.cloneNode(true));
              if (!urlAux.includes("app.getbillage.com")) {
                span.onclick = "";
                span.addEventListener("click", function (ev) {
                  _self.show("modal", urlAux);
                });
              }
            }
            const pageWindow = document.getElementsByTagName("html")[0];
            pageWindow.addEventListener("mouseleave", function () {
              _self.show("modal");
            });
          });
          saveCookie("precios", "1");
        }
      }

      const params = new URLSearchParams(this.props.location.search);
      const utm_source = params.get("utm_source");
      const utm_medium = params.get("utm_medium");
      const utm_campaign = params.get("utm_campaign");
      const idReseller = params.get("idReseller");

      if (idReseller) {
        const buttons = document.querySelectorAll(".boton-registrar a, .boton-arrow");
        buttons.forEach(box => {
          box.href = "https://app.getbillage.com/saas/register/view-register?utm_source=" + utm_source + "&utm_medium=" + utm_medium + "&utm_campaign=" + utm_campaign + "&idReseller=" + idReseller;
        });

        // update precios
        const prices = document.querySelectorAll(".plan-price.plan-mensual");
        prices.forEach(priceButton => {
          const oldPrice = priceButton.innerHTML;
          let newPrice = oldPrice.replace("€/mes", "").replace("*", "");
          newPrice = newPrice / 2;
          priceButton.innerHTML = "<span class='line-through'>" + oldPrice.replace("*", "") + "</span> " + newPrice + "€/mes";
        });

        const planSoloTrimestral = document.querySelector(".plan-solo .plan-price.plan-mensual");
        const planSoloAnual = document.querySelector(".plan-solo .plan-price.plan-anual");
        planSoloAnual.innerHTML = planSoloTrimestral.innerHTML;

        const planSidecarTrimestral = document.querySelector(".plan-sidecar .plan-price.plan-mensual");
        const planSidecarAnual = document.querySelector(".plan-sidecar .plan-price.plan-anual");
        planSidecarAnual.innerHTML = planSidecarTrimestral.innerHTML;

        const planTeamTrimestral = document.querySelector(".plan-team .plan-price.plan-mensual");
        const planTeamAnual = document.querySelector(".plan-team .plan-price.plan-anual");
        planTeamAnual.innerHTML = planTeamTrimestral.innerHTML;

        const planUltimateTrimestral = document.querySelector(".plan-ultimate .plan-price.plan-mensual");
        const planUltimateAnual = document.querySelector(".plan-ultimate .plan-price.plan-anual");
        planUltimateAnual.innerHTML = planUltimateTrimestral.innerHTML;

        const switchMain = document.querySelector("#switchMain");
        switchMain.style.display = "none";
        const titulo = document.querySelector(".titulo-home");
        titulo.innerHTML = "Prueba billage con un 50% de descuento";
        titulo.style.marginBottom = "40px";

        const textoPrecios = document.querySelector(".precios-sin-iva p:last-child");
        textoPrecios.innerHTML = "*Precio con descuento solamente durante el primer año";
      }
    }

    this.dataLayerEvents();
  }

  dataLayerEvents () {
    Array.from(document.querySelectorAll(".plan-box a.boton")).forEach((menuButton, i) => {
      menuButton.addEventListener("click", function (ev) {
        let typePayment = "Pago anual";
        if (!document.getElementsByClassName("tipoPago")[0].checked) {
          typePayment = "Pago trimestral";
        }
        const typePlan = ev.currentTarget.closest(".plan-box").querySelector(".plan-titulo").textContent;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "probar_gratis_card",
          probargratiscard: {
            action: {
              card: typePlan,
              pay_type: typePayment, // Este es un campo dinámico
              action: "Tipo de pago"
            }
          }
        });
      });
    });
    if (document.querySelector(".precios-moreInfo .boton") !== null) {
      document.querySelector(".precios-moreInfo .boton").addEventListener("click", function (ev) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "planifica_demo",
          planificademo: {
            action: {
              action: "Planifica una demo"
            }
          }
        });
      });
    }

    Array.from(document.querySelectorAll(".landing-comercial #cluster .saber-mas")).forEach((saberMas, i) => {
      // const link = saberMas.getAttribute("onClick");
      // saberMas.dataset.link = link;
      // saberMas.removeAttribute("onClick");
      saberMas.addEventListener("click", function (ev) {
        const crmProgram = ev.currentTarget.closest(".cluster-item").querySelector("h3").textContent;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "saber_mas_crm",
          sabermascrm: {
            action: {
              servicio: crmProgram,
              action: "Saber más crm"
            }
          }
        });
      });
    });

    Array.from(document.querySelectorAll(".landing-facturacion #cluster .saber-mas")).forEach((saberMas, i) => {
      // const link = saberMas.getAttribute("onClick");
      // saberMas.dataset.link = link;
      saberMas.removeAttribute("onClick");
      saberMas.addEventListener("click", function (ev) {
        const facProgram = ev.currentTarget.closest(".cluster-item").querySelector("h3").textContent;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "saber_mas_facturacion",
          sabermas: {
            action: {
              servicio: facProgram,
              action: "Saber más"
            }
          }
        });
      });
    });
  }

  removeScript (scriptToremove) {
    const allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--) {
      if (
        allsuspects[i] &&
        allsuspects[i].getAttribute("src") !== null &&
        allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1
      ) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  }

  componentWillUnmount () {
    // this.removeScript("https://code.jquery.com/jquery-3.6.0.min.js");
    this.removeScript("/customjs/jquery-ui.min.js");
    this.removeScript("/customjs/owl.carousel.min.js");
    this.removeScript("/customjs/toggles.js");
    this.removeScript("/customjs/custom.js");
  }

  // Crear query en template page per integraciones (recuperar tots els posts de la categoria i passar-ho per propietats)
  // Una vegada s'hagi carregat el VDOM afegir els posts de cada categoria com a attribut i desde el jquery o aquí mateix crear la funcionalitat de tags
  render () {
    const langPage = this.props.pageContext.idioma;
    const seo = this.props.data.wpPage.seo;
    const page = this.props.data.wpPage;
    const idPage = page.databaseId;
    const app_json = this.createSchema(seo, page, langPage);
    let slugPage = seo.canonical;
    const contacto_answer = JSON.stringify(dictionary[langPage].contacto);
    let openGraphslugPage = seo.opengraphUrl;

    if (idPage === 82 || idPage === 17248 || idPage === 17247) {
      slugPage = "/" + langPage + "/";
      openGraphslugPage = "/" + langPage + "/";
    }

    const developers = this.props.data.wpPage.developers;
    const alternates = page.translated;
    const breadcrumb = [];
    if (page.ancestors !== null && page.ancestors !== undefined) {
      let item = {
        title: page.ancestors.nodes[0].title,
        slug: page.ancestors.nodes[0].link
      };
      breadcrumb.push(item);
      item = {
        title: page.title,
        slug: null
      };
      breadcrumb.push(item);
    }
    console.log("visible1", this.state.modal);
    return (
      <Layout
        currentPage={page.title}
        langPage={langPage}
        idPage={this.props.data.wpPage.id}
        id_pagina={developers !== null ? developers.idPagina : ""}
        clase_pagina={developers !== null ? developers.clasePagina : ""}
        alternates={alternates}
      >
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          canonical={slugPage}
          robotsNofollow={seo.metaRobotsNofollow}
          robotsNoindex={seo.metaRobotsNoindex}
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null
              ? seo.opengraphImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : 300
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : 300
          }
          dctermsCreated={this.props.data.wpPage.date}
          dctermsModified={this.props.data.wpPage.modified}
          lang={langPage}
          twitterImg={
            seo.twitterImage !== null
              ? seo.twitterImage.sourceUrl
              : "/images/logo-billage-default-social-share.jpg"
          }
          twitterImgHeight={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.height
              : 300
          }
          twitterImgWidth={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.width
              : 300
          }
          alternates={alternates}
          appJson={app_json}
          idPage={idPage}
        />
        <div>{breadcrumb.length > 0
          ? <Breadcrumb props={breadcrumb} isChild={page.ancestors === null ? "true" : "false"} isPage="true"/>
          : null
        }
        </div>
        <div
          className="row page-content"
          data-lang={langPage}
          data-contact={contacto_answer}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.data.wpPage.page_content.contentRaw
            }}
          />
        </div>
        {this.state.modal
          ? <ModalPrecio currentUrl={this.state.currentUrl} hideModal={this.hideModalPrecio}/>
          : null}
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query currentPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      slug
      id
      databaseId
      locale {
        id
      }
      page_content {
        contentRaw
      }
      developers {
        idPagina
        clasePagina
      }
      aplication_json_ld_reviews_gql {
        aplicationJsonLdReviews
      }
      date
      uri
      modified
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
      author {
        node {
          name
          slug
          avatar {
            url
          }
        }
      }
      translated {
        seo {
          canonical
        }
        locale{
          locale
        }
      }
      ancestors {
      nodes {
        slug
        link
        ... on WpPage {
          id
          title
        }
      }
    }
    }
  }
`;
