// Packages
import cookie from "react-cookies";

export const saveCookie = (cookie_name, value) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 30);

  return cookie.save(cookie_name, value, { expires });
};

export const deleteCookie = (cookie_name) => {
  cookie.remove(cookie_name);
};

export const getCookie = (cookie_name) => {
  // const cookies = getAllCookies();
  // console.log("COOKIES", cookies);
  // if (Object.keys(cookies).length > 0) {
  //   return cookies && cookies[cookie_name] && cookies[cookie_name];
  // }
  const cookieValue = cookie.load(cookie_name);
  return cookieValue !== undefined ? cookieValue : false;
};
