import React from "react";
import Rodal from "rodal";
import { graphql, useStaticQuery } from "gatsby";
// include styles
import "rodal/lib/rodal.css";

class ModalPrecio extends React.Component {
  constructor (props) {
    super(props);

    this.state = { visible: true };
    this.hide = this.hide.bind(this);
    // this.redirectUrl = this.redirectUrl.bind(this);
  }

  hide () {
    if (this.props.currentUrl !== null) {
      location.assign(this.props.currentUrl);
      return;
    }
    this.props.hideModal();
  }

  // redirectUrl () {
  //   location.assign(this.props.currentUrl);
  // }

  render () {
    return (
        <Rodal className="basic-modal" visible={this.state.visible} onClose={this.hide}>
          <PageData/>
          {/* <div><button onClick={this.redirectUrl}> Si</button> <button onClick={this.hide}> No</button></div> */}
        </Rodal>
    );
  }
}
export default ModalPrecio;

const PageData = () => {
  const data = useStaticQuery(
    graphql`
      {
        wpPage(databaseId: { eq: 36571 }) {
          content
        }
      }
    `
  );

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: data.wpPage.content
      }}
    />
  );
};
